import React, { useState } from 'react'
import { graphql } from 'gatsby'
import IndexLayout from '../../layouts'
import SectionedIngredients from '../../components/shared/SectionedIngredients'
import Testimonials from '../../components/homepage/Testimonials'
import { getAllIngredients } from '../../common/ingredients'
import { CartContext } from '../../components/shared/CartProvider'
import SelectRecipes from '../../components/shared/LandingComponents/SelectRecipes'
import scrollTo from 'gatsby-plugin-smoothscroll'

import Groceries from '../../images/svgs/groceries.svg'
import Recipe from '../../images/svgs/recipe.svg'
import Vegetables from '../../images/svgs/vegetables.svg'
import User from '../../images/svgs/user.svg'

import HowItWorks from '../../components/shared/LandingComponents/HowItWorks'

const recipeSlugs = [
  'tahini-quinoa',
  'bulgur-with-vegetables',
  'grilled-romaine-with-baba-ganoush',
  'smokey-lentils-and-greens',
  'creamy-cashew-pasta',
  'harissa-tofu-toast'
]


const PlantBasedStarter = ({ data }) => {
  const [selectedRecipes, setSelectedRecipes] = useState([])
  const [filterRecipe, setFilterRecipe] = useState(null)

  const recipes = data.allContentfulRecipe.nodes.filter(recipe => recipeSlugs.indexOf(recipe.slug) != -1)
  const setFilter = (recipe) => {
    if (recipe == filterRecipe) {
      setFilterRecipe(null)
    } else {
      setFilterRecipe(recipe)
    }
  }
  const allIngredients = getAllIngredients(recipes.filter(recipe => selectedRecipes.indexOf(recipe.slug) != -1), filterRecipe)

  return (
    <IndexLayout>
      <div className="section header bg-light">
        <div className="container bg-light pt-5">
          <div className="header-index bg-light mb-0 pb-1">
            <div className="row">
              <div className="col-12 col-md-7 py-4 d-flex flex-column justify-content-center">
                <h1 className="font-weight-bold display-1">
                  Make plant-based easy
                </h1>
                <h4>
                  Take all the thinky bits out of planning a plant-based diet.
                </h4>
                <a className={`btn btn-lg btn-primary mt-3 mr-5 d-print-none mb-5`}
                  onClick={() => scrollTo('#pick-recipes')}>
                  Get Started
                </a>
              </div>
              <div className="col-12 col-md-4">
                <img className="img-fluid bowl" alt="Eating Plants" src={data.groceryImage.childImageSharp.fluid.src} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <HowItWorks cellData={[
        { icon: Recipe, text: "Select some recipes that look good to you. We'll deliver the ingredients to your door.", header: "Recipes" },
        { icon: Vegetables, text: "Tell us a little bit more about how you like to eat. What foods you like, how often you cook, etc.", header: "Talk to us" },
        { icon: Groceries, text: "We'll suggest new grocery lists based on your meal reviews and preferences.", header: "Repeat" }
      ]} />

      <Testimonials />
      <section className="section bg-light mb-5">
        <div className="container bg-light position-relative pb-5">
          <img className="position-absolute w-25 d-none d-md-block" style={{ top: '0  px', left: '-30px' }} src={data.lentilImage.childImageSharp.fluid.src} />
          <img className="position-absolute d-none d-md-block w-20" style={{ bottom: '0px', right: '30px' }} src={data.pozoleImage.childImageSharp.fluid.src} />
          <div className="header-index bg-light mb-0 pb-5 pt-5">
            <div className="row pt-0 pt-md-3">
              <div className="col-12 col-md-8 pt-4 d-flex flex-column justify-content-end">
                <h3 className="font-weight-bold">
                  Tired of trying to change the way you eat? We can help!
                </h3>
                <p className="font-weight-light">
                  It should be easy to eat the way you want. It's <b>not</b> about your willpower, or discipline. It's about having the right food available at the right time,
                  and it's about your grocery store being there to support you.
                </p>
              </div>
              <div className="col-12 col-md-4 d-flex flex-column justify-content-center">
                <img className="img-fluid" src={data.quinoaImage.childImageSharp.fluid.src} />
              </div>
              <div className="col-12">
                <p className="font-weight-light">
                  Stop worrying about figuring out what to buy. We do that for you. Stop worrying about going to the supermarket hungry, we suggest great food at times that are right for you!
              Best of all, there's no subscription! It's just the groceries you want when <b>you</b> need them.
                </p>
                <h4 className="font-weight-bold">Let us worry about food</h4>
                <p className="font-weight-light">
                  We're here for you every step of the way. No matter how much or how little the change you're looking to make, it's easier with ELM. We get to know you and your preferences to ensure
                  we're bringing exactly the right amount of support.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <SelectRecipes recipes={recipes} setRecipes={setSelectedRecipes} />
      <div className="container pt-4">
        <div className="row">
          <div className="col-12">
            <h2 className="font-weight-bold">2 - Shop for ingredients</h2>
            {selectedRecipes.length == 0 && <p className="small">Select a few recipes above to get started</p>}
            {selectedRecipes.length == 1 && <p className="small">Everything you need to make this recipe. Select what you need below.</p>}
            {selectedRecipes.length > 1 && <p className="small">Everything you need to make these {selectedRecipes.length} recipes. Select what you need below.</p>}
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <p className="smaller font-weight-bold mb-2">Tap a recipe below to view only it's ingredients</p>
          </div>
        </div>
        <div className="row scroll-horizontal-md border-top">
          {recipes.filter(recipe => selectedRecipes.indexOf(recipe.slug) != -1).map((recipe, index) => {
            const selected = filterRecipe === recipe

            return (
              <div className={`cursor-pointer col-5 col-sm-3 col-xl-2 rounded py-2 text-center ${selected ? 'bg-dark' : ''}`} key={`filter-recipe-${index}`} onClick={() => {
                setFilter(recipe)
              }}>
                <img className="img-fluid" src={recipe.rectangularImage?.fluid?.src} alt="The recipe" />
                <div style={{ height: "60px" }}>
                  <div className="smaller font-weight-bold pt-1 white-space-wrap limit-lines-two">{recipe.name}</div>
                </div>
              </div>
            )
          })}
        </div>
        <SectionedIngredients ingredients={allIngredients} />
      </div>
      <section className="pick-ingredients">
        <div className="container">
          <CartContext.Consumer>
            {(cart) => {
              return (
                <div className="row pt-4">
                  <div className="col-8 col-md-4 mx-auto text-center">
                    {cart.contents.length > 0 && <>
                      <button className="btn btn-primary btn-block" onClick={cart.toggleCart}>Checkout</button>
                      <p className="smaller">{'\u00A0'}</p>
                    </>
                    }
                    {cart.contents.length == 0 && <>
                      <button className="btn btn-primary btn-block disabled">Checkout</button>
                      <p className="smaller">Add some items to your cart</p>
                    </>
                    }
                  </div>
                </div>
              )
            }}
          </CartContext.Consumer>
        </div>
      </section>
    </IndexLayout >
  )
}

export const query = graphql`
  query {
    groceryImage: file(relativePath: { eq: "images/Ingredients.png" }) {
      size
      childImageSharp {
        fluid {
          src
          sizes
        }
      }
    }
    lentilImage: file(relativePath: { eq: "food/potato-lentil.png" }) {
      size
      childImageSharp {
        fluid {
          src
          sizes
        }
      }
    }
    quinoaImage: file(relativePath: { eq: "food/tahini-quinoa-2.png" }) {
      size
      childImageSharp {
        fluid {
          src
          sizes
        }
      }
    }
    pozoleImage: file(relativePath: { eq: "food/pozole.png" }) {
      size
      childImageSharp {
        fluid {
          src
          sizes
        }
      }
    }
    allContentfulRecipe(filter: {nonProduction: {ne: true}}) {
      nodes {
        name
        slug
        shortDescription {
          childMarkdownRemark {
            html
          }
        }
        rectangularImage {
          file {
            url
          }
          fluid {
            src
          }
        }
        ingredients {
          amount
          ingredient {
            name
            sku
            priceCents
            id
            section
            quantityDescription
            brand
            picture {
              fluid(quality: 100, maxWidth:150) {
                src
              }
            }
          }
        }
      }
    }
  }
`

export default PlantBasedStarter
